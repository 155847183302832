<script>
import Layout from "../layouts/main";
import PageHeader from "../components/page-header";

/**
 * Dashboard Component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Welcome to TripleS dashboard",
          active: true
        }
      ],
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
  </Layout>
</template>
