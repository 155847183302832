<script>
import axios from "axios";
import Form from 'vform'
import Swal from 'sweetalert2'
import Cropper from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import Layout from '../layouts/main'
import PageHeader from '../components/page-header'
import PaginationComponent from '../components/partials/PaginationComponent'

window.Form = Form

/**
 * Products component
 */
export default {
  components: { Layout, PageHeader, PaginationComponent },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('user')),
      title: 'Products',
      breadCrumbs: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Products',
          active: true,
        },
      ],
      showModal: false,
      addEditModal: false,
      editMode: false,
      query: "",
      queryField: "name",
      products: [
        {
          id: 0,
          user: "",
          company: "",
          category: "",
          name: "Loading",
          image: "/images/no-image.jpg",
          price: ""
        },
      ],
      companies: [],
      categories: [],
      form: new Form({
        busy: false,
        id: "",
        name: "",
        image: "/images/no-image.jpg",
        user: {},
        company: {},
        category: {},
        user_id: "",
        company_id: "",
        category_id: "",
        price: "",
        description: "",
        is_active: ""
      }),
      image: '',
      imageResize: '',
      selectImage: false,
      uploadImage: false,
      pagination: {
        current_page: 1
      }
    }
  },
  watch: {
    query: function(newQ, old) {
      if (newQ === "") {
        this.getData();
      } else {
        this.searchData();
      }
    }
  },
  mounted() {
    this.getData();
    this.getCompanies();
    this.getCategoriesByUser();
  },
  methods: {
    resizeImage({ coordinates, canvas }) {
      this.image = canvas.toDataURL();
			console.log(coordinates, canvas);
		},
    chooseImage() {
      document.getElementById("imageInput").click();
    },
    changeImage(e) {
      var reader, files = e.target.files;
      if(files.length === 0){
        return null;
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.imageResize = e.target.result;
        this.selectImage = true;
        this.form.busy = true;
      }
      reader.readAsDataURL(files[0]);
    },
    uploadImageFunc() {
      this.$Progress.start();

      let formData = new FormData();
      let data = this.image;
      formData.append('product', data);

      axios
        .post("/api/cards/updateImage", formData)
        .then(response => {
          this.$Progress.finish();
          // Prepare data
          this.image = response.data;
          this.imageResize = '';
          this.uploadImage = true;
          this.form.busy = false;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    getCompanies() {
      axios
        .get("/api/allCompanies", {
          params: {
            user: this.currentUser.id
          }
        })
        .then(response => {
          this.companies = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getCategoriesByUser() {
      axios
        .get("/api/getCategoriesByUser", {
          params: {
            user: this.currentUser.id
          }
        })
        .then(response => {
          this.categories = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getCategoriesByCompany(companyId) {
      axios
        .get("/api/getCategoriesByCompany", {
          params: {
            company: companyId
          }
        })
        .then(response => {
          this.categories = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getData() {
      this.$Progress.start();
      axios
        .get("/api/products", {
          params: {
            page: this.pagination.current_page,
            user: this.currentUser.id
          }
        })
        .then(response => {
          this.$Progress.finish();
          this.products = response.data.data;
          this.pagination = response.data;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    searchData() {
      this.$Progress.start();
      axios
        .get(
          "/api/search/products/" +
          this.queryField +
          "/" +
          this.query +
          "?page=" +
          this.pagination.current_page +
          "&user=" + this.currentUser.id
        )
        .then(response => {
          this.$Progress.finish();
          this.products = response.data.data;
          this.pagination = response.data;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    reload() {
      this.getData();
      this.query = "";
      this.queryField = "name";
    },
    create() {
      this.reset();
      this.editMode = false;
      this.addEditModal = true;
    },
    store() {
      this.$Progress.start();
      this.form.busy = true;
      this.form.userId = this.currentUser.id;
      // If changed image
      this.form.image = this.uploadImage ? this.image : this.form.image;
      // If image not uploaded
      this.form.image = (this.form.image == "/images/no-image.jpg") ? "" : this.form.image;

      this.form
        .post("/api/products")
        .then(response => {
          this.getData();
          this.addEditModal = false;
          if (this.form.successful) {
            this.$Progress.finish();
            Swal.fire({
              title: 'Created!',
              text: 'Successfully Created.',
              icon: 'success'
            })
          } else {
            this.$Progress.fail();
            Swal.fire({
              title: 'Something wrong!',
              text: 'Something went wrong try again later',
              icon: 'error'
            })
          }
        })
        .catch(e => {
          this.$Progress.fail();
            // If image not uploaded
            this.form.image = (this.form.image == "") ? "/images/no-image.jpg" : this.form.image;
          console.log(e);
        });
    },
    show(product) {
      this.reset();
      this.form.fill(product);
      this.showModal = true;
    },
    edit(product) {
      this.reset();
      this.editMode = true;
      this.form.fill(product);
      this.getCategoriesByCompany(this.form.company_id);
      this.addEditModal = true;
    },
    update() {
      this.$Progress.start();
      this.form.busy = true;
      // If changed image
      this.form.image = this.uploadImage ? this.image : this.form.image;

      this.form
        .put("/api/products/" + this.form.id)
        .then(response => {
          this.getData();
          this.addEditModal = false;
          if (this.form.successful) {
            this.$Progress.finish();
            Swal.fire({
              title: 'Updated!',
              text: 'Successfully Updated.',
              icon: 'success'
            })
          } else {
            this.$Progress.fail();
            Swal.fire({
              title: 'Spmething wrong!',
              text: 'Something went wrong try again later',
              icon: 'error'
            })
          }
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    destroy(product) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#74788d',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {          
          this.$Progress.start();
          axios
            .delete("/api/products/" + product.id)
            .then(response => {
              this.$Progress.finish();
              this.getData();
              Swal.fire({
                title: 'Deleted!',
                text: 'Your item has been deleted.',
                icon: 'success'
              })
            })
            .catch(e => {
              this.$Progress.fail();
              Swal.fire({
                title: 'Spmething wrong!',
                text: 'Something went wrong try again later',
                icon: 'error'
              })
            });
        }
      })
    },
    reset(){
      this.selectImage = false;
      this.uploadImage = false;
      this.imageResize = '';
      this.form.reset();
      this.form.clear();
    },
    selectCompany(e) {
      this.form.company_id = e.target.value;
      this.getCategoriesByCompany(e.target.value);
    },
    selectCategory(e) {
      this.form.category_id = e.target.value;
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadCrumbs" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <!-- Search & Buttons -->
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input type="text" v-model="query" class="form-control" placeholder="Search..." />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <button type="button" class="btn btn-success btn-rounded mb-2 me-2" @click="create">
                    <i class="mdi mdi-plus me-1"></i> Add New Product
                  </button>
                  <button type="button" class="btn btn-secondary btn-rounded mb-2 me-2" @click="reload">
                    <i class="mdi mdi-sync me-1"></i> Reload
                  </button>
                </div>
              </div>
              <!-- end col-->
            </div>

            <!-- Table data -->
            <div class="table-responsive mb-0">
              <table class="table table-centered table-nowrap align-middle">
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px">№</th>
                    <th class="align-middle">Image</th>
                    <th class="align-middle">Name</th>
                    <th v-if="currentUser.id == 1" class="align-middle">User</th>
                    <th v-if="currentUser.id == 1 || currentUser.account_type == 'company'" class="align-middle">Company</th>
                    <th class="align-middle">Category</th>
                    <th class="align-middle">Price</th>
                    <th class="align-middle">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="products.length"
                    v-for="(product, index) in products"
                    :key="product.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td><img v-bind:src="'/storage' + product.image" width="60" /></td>
                    <td>{{ product.name }}</td>
                    <td v-if="currentUser.id == 1">{{ product.user ? product.user.name : "" }}</td>
                    <td v-if="currentUser.id == 1 || currentUser.account_type == 'company'">{{ product.company ? product.company.name : "" }}</td>
                    <td>{{ product.category ? product.category.name : "" }}</td>
                    <td>{{ product.price }}</td>
                    <td>
                      <button type="button" @click="show(product)" class="btn btn-info btn-sm">
                        <i class="fas fa-eye"></i>
                      </button>
                      <button type="button" @click="edit(product)" class="btn btn-primary btn-sm">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        @click="destroy(product)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-show="!products.length">
                    <td colspan="8">
                      <div class="alert alert-danger" role="alert">Sorry :( No data found.</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <PaginationComponent
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="5"
              @paginate="query === '' ? getData() : searchData()"
            />

            <!-- Show Modal -->
            <b-modal v-model="showModal" title="Product Details" centered>
              <div class="table-responsive">
                <table class="table table-centered table-nowrap">
                  <tbody>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Name:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.name }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Image:
                          </h5>
                        </div>
                      </th>
                      <td><img v-bind:src="'/storage' + form.image" width="100" /></td>
                    </tr>
                    <tr v-if="currentUser.id == 1">
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            User:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.user ? form.user.name : "" }}</td>
                    </tr>
                    <tr v-if="currentUser.id == 1 || currentUser.account_type == 'company'">
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Company:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.company ? form.company.name : "" }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Category:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.category ? form.category.name : "" }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Price:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.price }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Description:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.description }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Active:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.is_active }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <template v-slot:modal-footer>
                <b-button variant="secondary" @click="showModal = false"
                  >Close</b-button
                >
              </template>
            </b-modal>

            <!-- Add or Edit Modal -->
            <b-modal 
              v-model="addEditModal" 
              :title="editMode ? 'Edit Product' : 'Add New Product'" 
              centered
              hide-footer
            >
              <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <alert-error :form="form"></alert-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Name</label>
                      <input
                        v-model="form.name"
                        type="text"
                        name="name"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('name') }"
                      >
                      <has-error :form="form" field="name"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Image <small>/Click image to upload/</small></label>
                      <img v-bind:src="selectImage ? image : '/storage' + form.image" width="100" @click="chooseImage" style="display:block; cursor:pointer;" />
                      <input
                        hidden
                        id="imageInput"
                        type="file"
                        name="image"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('image') }"
                        @change="changeImage"
                      >
                      <has-error :form="form" field="image"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <cropper class="mb-3"
                        :src="imageResize"
                        :stencil-props="{
                          aspectRatio: 1/1
                        }"
                        @change="resizeImage"
                      />
                      <b-button v-if="(selectImage && !uploadImage)" variant="success" @click="uploadImageFunc()"
                        >Upload</b-button
                      >
                    </div>
                  </div>
                  <div v-if="currentUser.id == 1 || currentUser.account_type == 'company'" class="col-12">
                    <div class="mb-3">
                      <label for="name">Company</label>
                      <select 
                        v-model="form.company_id"
                        name="company_id"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('company_id') }"
                        @change="selectCompany($event)"
                      >
                        <option disabled value="">Select</option>
                        <option
                          v-for="(company, index) in companies"
                          :value="company.id"
                          :selected="(company.id == form.company_id) ? true : false"
                        >{{ company.name }}</option>
                      </select>
                      <has-error :form="form" field="company_id"></has-error>
                    </div>
                  </div>
                  <div v-if="form.company_id != '' || currentUser.account_type == 'personal'" class="col-12">
                    <div class="mb-3">
                      <label for="name">Category</label>
                      <select 
                        v-model="form.category_id"
                        name="category_id"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('category_id') }"
                        @change="selectCategory($event)"
                      >
                        <option disabled value="">Select</option>
                        <option
                          v-for="(category, index) in categories"
                          :value="category.id"
                          :selected="(category.id == form.category_id) ? true : false"
                        >{{ category.name }}</option>
                      </select>
                      <has-error :form="form" field="category_id"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Price</label>
                      <input
                        v-model="form.price"
                        type="text"
                        name="price"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('price') }"
                      >
                      <has-error :form="form" field="price"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Description</label>
                      <input
                        v-model="form.description"
                        type="text"
                        name="description"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('description') }"
                      >
                      <has-error :form="form" field="description"></has-error>
                    </div>
                  </div>
                </div>

                <div class="text-end pt-5 mt-3">
                  <b-button variant="secondary" @click="addEditModal = false"
                    >Close</b-button
                  >
                  <b-button :disabled="form.busy" type="submit" variant="success" class="ms-1"
                    >Save changes</b-button
                  >
                </div>
              </form>
            </b-modal>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <vue-progress-bar></vue-progress-bar>
  </Layout>
</template>
