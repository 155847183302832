<script>
import axios from "axios";
import Form from 'vform'
import Swal from 'sweetalert2'
import Cropper from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import Layout from '../layouts/main'
import PageHeader from '../components/page-header'
import PaginationComponent from '../components/partials/PaginationComponent'

window.Form = Form

/**
 * Cards component
 */
export default {
  components: { Layout, PageHeader, PaginationComponent },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('user')),
      title: 'Cards',
      breadCrumbs: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Cards',
          active: true,
        },
      ],
      showModal: false,
      addEditModal: false,
      editMode: false,
      query: "",
      queryField: "firstname",
      cards: [
        {
          id: 0,
          firstname: "Loading",
          lastname: "",
          image: "/images/no-image.jpg",
          cover: "/images/no-image.jpg",
          company: "",
          company_name: "",
          position: "",
          phone: "",
          email: ""
        },
      ],
      defaultCardId: null,
      form: new Form({
        busy: false,
        id: "",
        firstname: "",
        lastname: "",
        image: "/images/no-image.jpg",
        cover: "/images/no-image.jpg",
        company: {},
        company_name: "",
        company_id: "",
        position: "",
        phone: "",
        email: "",
        website: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        linkedin: "",
        wechat: "",
        note: "",
        address1: "",
        address2: "",
        city: "",
        postal_code: "",
        country: ""
      }),
      image: '',
      imageResize: '',
      selectImage: false,
      uploadImage: false,
      cover: '',
      coverResize: '',
      selectCover: false,
      uploadCover: false,
      pagination: {
        current_page: 1
      }
    }
  },
  watch: {
    query: function(newQ, old) {
      if (newQ === "") {
        this.getData();
      } else {
        this.searchData();
      }
    }
  },
  mounted() {
    this.getData();
    this.getDefaultCardId();
  },
  methods: {
    resizeImage({ coordinates, canvas }) {
      this.image = canvas.toDataURL();
			console.log(coordinates, canvas);
		},
    chooseImage() {
      document.getElementById("imageInput").click();
    },
    changeImage(e) {
      var reader, files = e.target.files;
      if(files.length === 0){
        return null;
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.imageResize = e.target.result;
        this.selectImage = true;
        this.form.busy = true;
      }
      reader.readAsDataURL(files[0]);
    },
    resizeCover({ coordinates, canvas }) {
      this.cover = canvas.toDataURL();
			console.log(coordinates, canvas);
		},
    chooseCover() {
      document.getElementById("coverInput").click();
    },
    changeCover(e) {
      var reader, files = e.target.files;
      if(files.length === 0){
        return null;
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.cover = e.target.result;
        this.coverResize = e.target.result;
        this.selectCover = true;
        this.form.busy = true;
      }
      reader.readAsDataURL(files[0]);
    },
    uploadImageFunc(field) {
      this.$Progress.start();

      let formData = new FormData();
      let data = (field == 'image') ? this.image : this.cover;
      formData.append('field', field);
      formData.append(field, data);

      axios
        .post("/api/cards/updateImage", formData)
        .then(response => {
          this.$Progress.finish();
          // Prepare data
          if(field == 'image'){
            this.image = response.data;
            this.imageResize = '';
            this.uploadImage = true;
          } else {
            this.cover = response.data;
            this.coverResize = '';
            this.uploadCover = true;
          }

          this.form.busy = false;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    getDefaultCardId() {
      axios
        .get("/api/getDefaultCardId", {
          params: {
            user: this.currentUser.id
          }
        })
        .then(response => {
          this.defaultCardId = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getData() {
      this.$Progress.start();
      axios
        .get("/api/myCards", {
          params: {
            page: this.pagination.current_page,
            user: this.currentUser.id
          }
        })
        .then(response => {
          this.$Progress.finish();
          this.cards = response.data.data;
          this.pagination = response.data;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    searchData() {
      this.$Progress.start();
      axios
        .get(
          "/api/search/myCards/" +
          this.queryField +
          "/" +
          this.query +
          "?page=" +
          this.pagination.current_page +
          "&user=" + this.currentUser.id
        )
        .then(response => {
          this.$Progress.finish();
          this.cards = response.data.data;
          this.pagination = response.data;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    reload() {
      this.getData();
      this.query = "";
      this.queryField = "lastname";
    },
    create() {
      this.reset();
      this.editMode = false;
      this.addEditModal = true;
    },
    store() {
      this.$Progress.start();
      this.form.busy = true;
      this.form.userId = this.currentUser.id;
      // If changed image
      this.form.image = this.uploadImage ? this.image : this.form.image;
      // If image not uploaded
      this.form.image = (this.form.image == "/images/no-image.jpg") ? "" : this.form.image;
      // If changed cover
      this.form.cover = this.uploadCover ? this.cover : this.form.cover;
      // If cover not uploaded
      this.form.cover = (this.form.cover == "/images/no-image.jpg") ? "" : this.form.cover;

      this.form
        .post("/api/myCards")
        .then(response => {
          this.getData();
          this.addEditModal = false;
          if (this.form.successful) {
            this.$Progress.finish();
            Swal.fire({
              title: 'Created!',
              text: 'Successfully Created.',
              icon: 'success'
            })
          } else {
            this.$Progress.fail();
            Swal.fire({
              title: 'Something wrong!',
              text: 'Something went wrong try again later',
              icon: 'error'
            })
          }
        })
        .catch(e => {
          this.$Progress.fail();
            // If image not uploaded
            this.form.image = (this.form.image == "") ? "/images/no-image.jpg" : this.form.image;
            // If cover not uploaded
            this.form.cover = (this.form.cover == "") ? "/images/no-image.jpg" : this.form.cover;
          console.log(e);
        });
    },
    show(card) {
      this.reset();
      this.form.fill(card);
      this.showModal = true;
    },
    setDefaultCard(card) {
      this.$Progress.start();
      this.form.busy = true;

      axios
        .get(
          "/api/setDefaultCard/" +
          card.id +
          "/" +
          card.user_id
        )
        .then(response => {
          this.$Progress.finish();
          this.getData();
          Swal.fire({
            title: 'Updated!',
            text: 'Successfully Updated.',
            icon: 'success'
          })
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
          Swal.fire({
            title: 'Spmething wrong!',
            text: 'Something went wrong try again later',
            icon: 'error'
          })
        });
    },
    edit(card) {
      this.reset();
      this.editMode = true;
      this.form.fill(card);
      this.addEditModal = true;
    },
    update() {
      this.$Progress.start();
      this.form.busy = true;
      // If changed image
      this.form.image = this.uploadImage ? this.image : this.form.image;
      // If changed cover
      this.form.cover = this.uploadCover ? this.cover : this.form.cover;

      this.form
        .put("/api/myCards/" + this.form.id)
        .then(response => {
          this.getData();
          this.addEditModal = false;
          if (this.form.successful) {
            this.$Progress.finish();
            Swal.fire({
              title: 'Updated!',
              text: 'Successfully Updated.',
              icon: 'success'
            })
          } else {
            this.$Progress.fail();
            Swal.fire({
              title: 'Spmething wrong!',
              text: 'Something went wrong try again later',
              icon: 'error'
            })
          }
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    destroy(card) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#74788d',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {          
          this.$Progress.start();
          axios
            .delete("/api/cards/" + card.id)
            .then(response => {
              this.$Progress.finish();
              this.getData();
              Swal.fire({
                title: 'Deleted!',
                text: 'Your item has been deleted.',
                icon: 'success'
              })
            })
            .catch(e => {
              this.$Progress.fail();
              Swal.fire({
                title: 'Spmething wrong!',
                text: 'Something went wrong try again later',
                icon: 'error'
              })
            });
        }
      })
    },
    reset(){
      this.selectImage = false;
      this.uploadImage = false;
      this.imageResize = '';
      this.selectCover = false;
      this.uploadCover = false;
      this.coverResize = '';
      this.form.reset();
      this.form.clear();
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadCrumbs" />

    <!-- Search & Buttons -->
    <div class="row mb-2">
      <div class="col-sm-4">
        <div class="search-box me-2 mb-2 d-inline-block">
          <div class="position-relative">
            <input type="text" v-model="query" class="form-control" placeholder="Search..." />
            <i class="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="text-sm-end">
          <a v-if="defaultCardId" :href="'/hash/' + defaultCardId" target="_blank" class="btn btn-info btn-rounded mb-2 me-2">
            <i class="far fa-id-card"></i> View Default Card
          </a>
          <button type="button" class="btn btn-success btn-rounded mb-2 me-2" @click="create">
            <i class="mdi mdi-plus me-1"></i> Add New Card
          </button>
          <button type="button" class="btn btn-secondary btn-rounded mb-2 me-2" @click="reload">
            <i class="mdi mdi-sync me-1"></i> Reload
          </button>
        </div>
      </div>
      <!-- end col-->
    </div>

    <!-- Cards -->
    <div class="row">
      <div v-show="cards.length" v-for="(card, index) in cards" :key="card.id" class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-12">
                <img v-bind:src="'/storage' + card.cover" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-4">
                <div class="avatar-md profile-user-wid mb-4" style="width: 5.5rem !important;">
                  <img v-bind:src="'/storage' + card.image" alt class="img-thumbnail rounded-circle" />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="pt-2">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="font-size-15 text-truncate">{{ card.firstname }} {{ card.lastname }}</h5>
                      <p class="text-muted mb-0">
                        {{ card.company_name ? card.company_name + ',' : '' }} 
                        {{ card.position ? card.position + ',' : '' }} 
                        {{ card.email ? card.email + ',' : '' }} 
                        {{ card.phone }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <button v-if="!card.is_default" type="button" @click="setDefaultCard(card)" class="btn btn-white btn-sm border border-secondary">
                  Set Default
                </button>
                <button v-if="card.is_default" type="button" disabled class="btn btn-white btn-sm border border-secondary">
                  <i class="fas fa-check"></i> Default
                </button>
                <button type="button" @click="show(card)" class="btn btn-info btn-sm">
                  <i class="far fa-eye"></i>
                </button>
                <button type="button" @click="edit(card)" class="btn btn-primary btn-sm">
                  <i class="fas fa-edit"></i>
                </button>
                <button type="button" @click="destroy(card)" class="btn btn-danger btn-sm">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>        
      </div>

      <div v-show="!cards.length" class="alert alert-danger" role="alert">Sorry :( No data found.</div>
    </div>

    <!-- Pagination -->
    <PaginationComponent
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :offset="5"
      @paginate="query === '' ? getData() : searchData()"
    />

    <!-- Show Modal -->
    <b-modal v-model="showModal" title="Card Details" centered>
      <div class="table-responsive">
        <table class="table table-centered table-nowrap">
          <tbody>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Firstname:
                  </h5>
                </div>
              </th>
              <td>{{ form.firstname }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Lastname:
                  </h5>
                </div>
              </th>
              <td>{{ form.lastname }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Image:
                  </h5>
                </div>
              </th>
              <td><img v-bind:src="'/storage' + form.image" width="100" /></td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Cover:
                  </h5>
                </div>
              </th>
              <td><img v-bind:src="'/storage' + form.cover" width="250" /></td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Company:
                  </h5>
                </div>
              </th>
              <td>{{ form.company_name }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Position:
                  </h5>
                </div>
              </th>
              <td>{{ form.position }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Phone:
                  </h5>
                </div>
              </th>
              <td>{{ form.phone }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Email:
                  </h5>
                </div>
              </th>
              <td>{{ form.email }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Website:
                  </h5>
                </div>
              </th>
              <td>{{ form.website }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Facebook:
                  </h5>
                </div>
              </th>
              <td>{{ form.facebook }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Twitter:
                  </h5>
                </div>
              </th>
              <td>{{ form.twitter }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Instagram:
                  </h5>
                </div>
              </th>
              <td>{{ form.instagram }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Youtube:
                  </h5>
                </div>
              </th>
              <td>{{ form.youtube }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Linkedin:
                  </h5>
                </div>
              </th>
              <td>{{ form.linkedin }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    WeChat:
                  </h5>
                </div>
              </th>
              <td>{{ form.wechat }}</td>
            </tr>
            <tr>
              <th scope="row" width="30%">
                <div>
                  <h5 class="text-truncate font-size-14">
                    Telegram:
                  </h5>
                </div>
              </th>
              <td>{{ form.telegram }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>

    <!-- Add or Edit Modal -->
    <b-modal 
      v-model="addEditModal" 
      :title="editMode ? 'Edit Card' : 'Add New Card'" 
      centered
      hide-footer
    >
      <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <alert-error :form="form"></alert-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Firstname</label>
              <input
                v-model="form.firstname"
                type="text"
                name="firstname"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('firstname') }"
              >
              <has-error :form="form" field="firstname"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Lastname</label>
              <input
                v-model="form.lastname"
                type="text"
                name="lastname"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('lastname') }"
              >
              <has-error :form="form" field="lastname"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Image <small>/Click image to upload/</small></label>
              <img v-bind:src="selectImage ? image : '/storage' + form.image" width="100" @click="chooseImage" style="display:block; cursor:pointer;" />
              <input
                hidden
                id="imageInput"
                type="file"
                name="image"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('image') }"
                @change="changeImage"
              >
              <has-error :form="form" field="image"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <cropper class="mb-3"
                :src="imageResize"
                :stencil-props="{
                  aspectRatio: 1/1
                }"
                @change="resizeImage"
              />
              <b-button v-if="(selectImage && !uploadImage)" variant="success" @click="uploadImageFunc('image')"
                >Upload</b-button
              >
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Cover <small>/Click image to upload/</small></label>
              <img v-bind:src="selectCover ? cover : '/storage' + form.cover" width="250" @click="chooseCover" style="display:block; cursor:pointer;" />
              <input
                hidden
                id="coverInput"
                type="file"
                name="cover"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('cover') }"
                @change="changeCover"
              >
              <has-error :form="form" field="cover"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <cropper class="mb-3"
                :src="coverResize"
                :stencil-props="{
                  aspectRatio: 5/2
                }"
                @change="resizeCover"
              />
              <b-button v-if="(selectCover && !uploadCover)" variant="success" @click="uploadImageFunc('cover')"
                >Upload</b-button
              >
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Company Name</label>
              <input
                v-model="form.company_name"
                type="text"
                name="company_name"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('company_name') }"
              >
              <has-error :form="form" field="company_name"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Position</label>
              <input
                v-model="form.position"
                type="text"
                name="position"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('position') }"
              >
              <has-error :form="form" field="position"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Phone</label>
              <input
                v-model="form.phone"
                type="text"
                name="phone"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('phone') }"
              >
              <has-error :form="form" field="phone"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Email</label>
              <input
                v-model="form.email"
                type="text"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('email') }"
              >
              <has-error :form="form" field="email"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Website (URL)</label>
              <input
                v-model="form.website"
                type="text"
                name="website"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('website') }"
              >
              <has-error :form="form" field="website"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Facebook (URL)</label>
              <input
                v-model="form.facebook"
                type="text"
                name="facebook"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('facebook') }"
              >
              <has-error :form="form" field="facebook"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Twitter (URL)</label>
              <input
                v-model="form.twitter"
                type="text"
                name="twitter"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('twitter') }"
              >
              <has-error :form="form" field="twitter"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Instagram (URL)</label>
              <input
                v-model="form.instagram"
                type="text"
                name="instagram"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('instagram') }"
              >
              <has-error :form="form" field="instagram"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Youtube (URL)</label>
              <input
                v-model="form.youtube"
                type="text"
                name="youtube"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('youtube') }"
              >
              <has-error :form="form" field="youtube"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Linkedin (URL)</label>
              <input
                v-model="form.linkedin"
                type="text"
                name="linkedin"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('linkedin') }"
              >
              <has-error :form="form" field="linkedin"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">WeChat (ID)</label>
              <input
                v-model="form.wechat"
                type="text"
                name="wechat"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('wechat') }"
              >
              <has-error :form="form" field="wechat"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Telegram (URL)</label>
              <input
                v-model="form.telegram"
                type="text"
                name="telegram"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('telegram') }"
              >
              <has-error :form="form" field="telegram"></has-error>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Note</label>
              <textarea
                v-model="form.note"
                type="text"
                name="note"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('note') }"
              ></textarea>
              <has-error :form="form" field="note"></has-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Street 1</label>
              <input
                v-model="form.street1"
                type="text"
                name="street1"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('street1') }"
              >
              <has-error :form="form" field="street1"></has-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Street 2</label>
              <input
                v-model="form.street2"
                type="text"
                name="street2"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('street2') }"
              >
              <has-error :form="form" field="street2"></has-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="name">City</label>
              <input
                v-model="form.city"
                type="text"
                name="city"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('city') }"
              >
              <has-error :form="form" field="city"></has-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Postal Code</label>
              <input
                v-model="form.postal_code"
                type="text"
                name="postal_code"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('postal_code') }"
              >
              <has-error :form="form" field="postal_code"></has-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Country</label>
              <input
                v-model="form.country"
                type="text"
                name="country"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('country') }"
              >
              <has-error :form="form" field="country"></has-error>
            </div>
          </div>
        </div>

        <div class="text-end pt-5 mt-3">
          <b-button variant="secondary" @click="addEditModal = false"
            >Close</b-button
          >
          <b-button :disabled="form.busy" type="submit" variant="success" class="ms-1"
            >Save changes</b-button
          >
        </div>
      </form>
    </b-modal>
    
    <vue-progress-bar></vue-progress-bar>
  </Layout>
</template>
