<script>
import axios from "axios";
import Form from 'vform'
import Swal from 'sweetalert2'
import Cropper from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import Layout from '../layouts/main'
import PageHeader from '../components/page-header'
import PaginationComponent from '../components/partials/PaginationComponent'

window.Form = Form

/**
 * Companies component
 */
export default {
  components: { Layout, PageHeader, PaginationComponent },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('user')),
      title: 'Companies',
      breadCrumbs: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Companies',
          active: true,
        },
      ],
      showModal: false,
      addEditModal: false,
      editMode: false,
      query: "",
      queryField: "name",
      companies: [
        {
          id: 0,
          name: "Loading",
          logo: "/images/no-image.jpg",
          cover: "",
          phone: ""
        },
      ],
      form: new Form({
        busy: false,
        id: "",
        logo: "/images/no-image.jpg",
        cover: "/images/no-image.jpg",
        name: "",
        phone: "",
        email: "",
        website: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        linkedin: "",
        wechat: "",
        telegram: "",
        note: "",
        address1: "",
        address2: "",
        city: "",
        postal_code: "",
        country: ""
      }),
      logo: '',
      logoResize: '',
      selectLogo: false,
      uploadLogo: false,
      cover: '',
      coverResize: '',
      selectCover: false,
      uploadCover: false,
      pagination: {
        current_page: 1
      }
    }
  },
  watch: {
    query: function(newQ, old) {
      if (newQ === "") {
        this.getData();
      } else {
        this.searchData();
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    resizeLogo({ coordinates, canvas }) {
      this.logo = canvas.toDataURL();
			console.log(coordinates, canvas);
		},
    chooseLogo() {
      document.getElementById("logoInput").click();
    },
    changeLogo(e) {
      var reader, files = e.target.files;
      if(files.length === 0){
        return null;
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.logo = e.target.result;
        this.logoResize = e.target.result;
        this.selectLogo = true;
        this.form.busy = true;
      }
      reader.readAsDataURL(files[0]);
    },
    resizeCover({ coordinates, canvas }) {
      this.cover = canvas.toDataURL();
			console.log(coordinates, canvas);
		},
    chooseCover() {
      document.getElementById("coverInput").click();
    },
    changeCover(e) {
      var reader, files = e.target.files;
      if(files.length === 0){
        return null;
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.cover = e.target.result;
        this.coverResize = e.target.result;
        this.selectCover = true;
        this.form.busy = true;
      }
      reader.readAsDataURL(files[0]);
    },
    uploadImage(field) {
      this.$Progress.start();

      let formData = new FormData();
      let data = (field == 'logo') ? this.logo : this.cover;
      formData.append('field', field);
      formData.append(field, data);

      axios
        .post("/api/companies/updateImage", formData)
        .then(response => {
          this.$Progress.finish();
          // Prepare data
          if(field == 'logo'){
            this.logo = response.data;
            this.logoResize = '';
            this.uploadLogo = true;
          } else {
            this.cover = response.data;
            this.coverResize = '';
            this.uploadCover = true;
          }

          this.form.busy = false;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    getData() {
      this.$Progress.start();
      axios
        .get("/api/companies", {
          params: {
            page: this.pagination.current_page,
            user: this.currentUser.id
          }
        })
        .then(response => {
          this.$Progress.finish();
          this.companies = response.data.data;
          this.pagination = response.data;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    searchData() {
      this.$Progress.start();
      axios
        .get(
          "/api/search/companies/" +
          this.queryField +
          "/" +
          this.query +
          "?page=" +
          this.pagination.current_page +
          "&user=" + this.currentUser.id
        )
        .then(response => {
          this.$Progress.finish();
          this.companies = response.data.data;
          this.pagination = response.data;
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    reload() {
      this.getData();
      this.query = "";
      this.queryField = "name";
    },
    create() {
      this.reset();
      this.editMode = false;
      this.addEditModal = true;
    },
    store() {
      this.$Progress.start();
      this.form.busy = true;
      this.form.userId = this.currentUser.id;
      // If changed logo
      this.form.logo = this.uploadLogo ? this.logo : this.form.logo;
      // If logo not uploaded
      this.form.logo = (this.form.logo == "/images/no-image.jpg") ? "" : this.form.logo;
      // If changed cover
      this.form.cover = this.uploadCover ? this.cover : this.form.cover;
      // If cover not uploaded
      this.form.cover = (this.form.cover == "/images/no-image.jpg") ? "" : this.form.cover;

      this.form
        .post("/api/companies")
        .then(response => {
          this.getData();
          this.addEditModal = false;
          if (this.form.successful) {
            this.$Progress.finish();
            Swal.fire({
              title: 'Created!',
              text: 'Successfully Created.',
              icon: 'success'
            })
          } else {
            this.$Progress.fail();
            Swal.fire({
              title: 'Something wrong!',
              text: 'Something went wrong try again later',
              icon: 'error'
            })
          }
        })
        .catch(e => {
          this.$Progress.fail();
            // If logo not uploaded
            this.form.logo = (this.form.logo == "") ? "/images/no-image.jpg" : this.form.logo;
            // If cover not uploaded
            this.form.cover = (this.form.cover == "") ? "/images/no-image.jpg" : this.form.cover;
          console.log(e);
        });
    },
    show(company) {
      this.reset();
      this.form.fill(company);
      this.showModal = true;
    },
    edit(company) {
      this.reset();
      this.editMode = true;
      this.form.fill(company);
      this.addEditModal = true;
    },
    update() {
      this.$Progress.start();
      this.form.busy = true;
      // If changed logo
      this.form.logo = this.uploadLogo ? this.logo : this.form.logo;
      // If changed cover
      this.form.cover = this.uploadCover ? this.cover : this.form.cover;

      this.form
        .put("/api/companies/" + this.form.id)
        .then(response => {
          this.getData();
          this.addEditModal = false;
          if (this.form.successful) {
            this.$Progress.finish();
            Swal.fire({
              title: 'Updated!',
              text: 'Successfully Updated.',
              icon: 'success'
            })
          } else {
            this.$Progress.fail();
            Swal.fire({
              title: 'Spmething wrong!',
              text: 'Something went wrong try again later',
              icon: 'error'
            })
          }
        })
        .catch(e => {
          this.$Progress.fail();
          console.log(e);
        });
    },
    destroy(company) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#74788d',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {          
          this.$Progress.start();
          axios
            .delete("/api/companies/" + company.id)
            .then(response => {
              this.$Progress.finish();
              this.getData();
              Swal.fire({
                title: 'Deleted!',
                text: 'Your item has been deleted.',
                icon: 'success'
              })
            })
            .catch(e => {
              this.$Progress.fail();
              Swal.fire({
                title: 'Spmething wrong!',
                text: 'Something went wrong try again later',
                icon: 'error'
              })
            });
        }
      })
    },
    reset(){
      this.selectLogo = false;
      this.uploadLogo = false;
      this.logoResize = '';
      this.selectCover = false;
      this.uploadCover = false;
      this.coverResize = '';
      this.form.reset();
      this.form.clear();
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadCrumbs" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <!-- Search & Buttons -->
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input type="text" v-model="query" class="form-control" placeholder="Search..." />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <button type="button" class="btn btn-success btn-rounded mb-2 me-2" @click="create">
                    <i class="mdi mdi-plus me-1"></i> Add New Company
                  </button>
                  <button type="button" class="btn btn-secondary btn-rounded mb-2 me-2" @click="reload">
                    <i class="mdi mdi-sync me-1"></i> Reload
                  </button>
                </div>
              </div>
              <!-- end col-->
            </div>

            <!-- Table data -->
            <div class="table-responsive mb-0">
              <table class="table table-centered table-nowrap align-middle">
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px">№</th>
                    <th class="align-middle">Logo</th>
                    <th class="align-middle">Name</th>
                    <th class="align-middle">Phone</th>
                    <th class="align-middle">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="companies.length"
                    v-for="(company, index) in companies"
                    :key="company.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td><img v-bind:src="'/storage' + company.logo" width="60" /></td>
                    <td>{{ company.name }}</td>
                    <td>{{ company.phone }}</td>
                    <td>
                      <button type="button" @click="show(company)" class="btn btn-info btn-sm">
                        <i class="fas fa-eye"></i>
                      </button>
                      <button type="button" @click="edit(company)" class="btn btn-primary btn-sm">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        @click="destroy(company)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-show="!companies.length">
                    <td colspan="5">
                      <div class="alert alert-danger" role="alert">Sorry :( No data found.</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <PaginationComponent
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="5"
              @paginate="query === '' ? getData() : searchData()"
            />

            <!-- Show Modal -->
            <b-modal v-model="showModal" title="Company Details" centered>
              <div class="table-responsive">
                <table class="table table-centered table-nowrap">
                  <tbody>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Company name:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.name }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Logo:
                          </h5>
                        </div>
                      </th>
                      <td><img v-bind:src="'/storage' + form.logo" width="100" /></td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Cover:
                          </h5>
                        </div>
                      </th>
                      <td><img v-bind:src="'/storage' + form.cover" width="250" /></td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Phone:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.phone }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Email:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.email }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Website:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.website }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Facebook:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.facebook }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Twitter:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.twitter }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Instagram:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.instagram }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Youtube:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.youtube }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Linkedin:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.linkedin }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            WeChat:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.wechat }}</td>
                    </tr>
                    <tr>
                      <th scope="row" width="30%">
                        <div>
                          <h5 class="text-truncate font-size-14">
                            Telegram:
                          </h5>
                        </div>
                      </th>
                      <td>{{ form.telegram }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <template v-slot:modal-footer>
                <b-button variant="secondary" @click="showModal = false"
                  >Close</b-button
                >
              </template>
            </b-modal>

            <!-- Add or Edit Modal  -->
            <b-modal 
              v-model="addEditModal" 
              :title="editMode ? 'Edit Company' : 'Add New Company'" 
              centered 
              hide-footer 
            >
              <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <alert-error :form="form"></alert-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Company Name</label>
                      <input
                        v-model="form.name"
                        type="text"
                        name="name"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('name') }"
                      >
                      <has-error :form="form" field="name"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Logo <small>/Click image to upload/</small></label>
                      <img v-bind:src="selectLogo ? logo : '/storage' + form.logo" width="100" @click="chooseLogo" style="display:block; cursor:pointer;" />
                      <input
                        hidden
                        id="logoInput"
                        type="file"
                        name="logo"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('logo') }"
                        @change="changeLogo"
                      >
                      <has-error :form="form" field="logo"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <cropper class="mb-3"
                        :src="logoResize"
                        :stencil-props="{
                          aspectRatio: 1/1
                        }"
                        @change="resizeLogo"
                      />
                      <b-button v-if="(selectLogo && !uploadLogo)" variant="success" @click="uploadImage('logo')"
                        >Upload</b-button
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Cover <small>/Click image to upload/</small></label>
                      <img v-bind:src="selectCover ? cover : '/storage' + form.cover" width="250" @click="chooseCover" style="display:block; cursor:pointer;" />
                      <input
                        hidden
                        id="coverInput"
                        type="file"
                        name="cover"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('cover') }"
                        @change="changeCover"
                      >
                      <has-error :form="form" field="cover"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <cropper class="mb-3"
                        :src="coverResize"
                        :stencil-props="{
                          aspectRatio: 5/2
                        }"
                        @change="resizeCover"
                      />
                      <b-button v-if="(selectCover && !uploadCover)" variant="success" @click="uploadImage('cover')"
                        >Upload</b-button
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Phone</label>
                      <input
                        v-model="form.phone"
                        type="text"
                        name="phone"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('phone') }"
                      >
                      <has-error :form="form" field="phone"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Email</label>
                      <input
                        v-model="form.email"
                        type="text"
                        name="email"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('email') }"
                      >
                      <has-error :form="form" field="email"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Website (URL)</label>
                      <input
                        v-model="form.website"
                        type="text"
                        name="website"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('website') }"
                      >
                      <has-error :form="form" field="website"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Facebook (URL)</label>
                      <input
                        v-model="form.facebook"
                        type="text"
                        name="facebook"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('facebook') }"
                      >
                      <has-error :form="form" field="facebook"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Twitter (URL)</label>
                      <input
                        v-model="form.twitter"
                        type="text"
                        name="twitter"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('twitter') }"
                      >
                      <has-error :form="form" field="twitter"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Instagram (URL)</label>
                      <input
                        v-model="form.instagram"
                        type="text"
                        name="instagram"
                        class="form-control"
                        aria-describedby="ig_input"
                        :class="{ 'is-invalid': form.errors.has('instagram') }"
                      >
                      <has-error :form="form" field="instagram"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Youtube (URL)</label>
                      <input
                        v-model="form.youtube"
                        type="text"
                        name="youtube"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('youtube') }"
                      >
                      <has-error :form="form" field="youtube"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Linkedin (URL)</label>
                      <input
                        v-model="form.linkedin"
                        type="text"
                        name="linkedin"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('linkedin') }"
                      >
                      <has-error :form="form" field="linkedin"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">WeChat (ID)</label>
                      <input
                        v-model="form.wechat"
                        type="text"
                        name="wechat"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('wechat') }"
                      >
                      <has-error :form="form" field="wechat"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Telegram (URL)</label>
                      <input
                        v-model="form.telegram"
                        type="text"
                        name="telegram"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('telegram') }"
                      >
                      <has-error :form="form" field="telegram"></has-error>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Note</label>
                      <textarea
                        v-model="form.note"
                        type="text"
                        name="note"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('note') }"
                      ></textarea>
                      <has-error :form="form" field="note"></has-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label for="name">Street 1</label>
                      <input
                        v-model="form.street1"
                        type="text"
                        name="street1"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('street1') }"
                      >
                      <has-error :form="form" field="street1"></has-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label for="name">Street 2</label>
                      <input
                        v-model="form.street2"
                        type="text"
                        name="street2"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('street2') }"
                      >
                      <has-error :form="form" field="street2"></has-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label for="name">City</label>
                      <input
                        v-model="form.city"
                        type="text"
                        name="city"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('city') }"
                      >
                      <has-error :form="form" field="city"></has-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label for="name">Postal Code</label>
                      <input
                        v-model="form.postal_code"
                        type="text"
                        name="postal_code"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('postal_code') }"
                      >
                      <has-error :form="form" field="postal_code"></has-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-3">
                      <label for="name">Country</label>
                      <input
                        v-model="form.country"
                        type="text"
                        name="country"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('country') }"
                      >
                      <has-error :form="form" field="country"></has-error>
                    </div>
                  </div>
                </div>

                <div class="text-end pt-5 mt-3">
                  <b-button variant="secondary" @click="addEditModal = false"
                    >Close</b-button
                  >
                  <b-button :disabled="form.busy" type="submit" variant="success" class="ms-1"
                    >Save changes</b-button
                  >
                </div>
              </form>
            </b-modal>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <vue-progress-bar></vue-progress-bar>
  </Layout>
</template>
