export const menuItemsCompany = [
    {
        id: 1,
        label: "menuitems.dashboards.text",
        icon: "bx-home-circle",
        link: "/"
    },
    {
        id: 2,
        label: "menuitems.companies.text",
        icon: "bx-building",
        link: "/companies"
    },
    {
        id: 3,
        label: "menuitems.cards.text",
        icon: "bx-id-card",
        link: "/cards"
    },
    {
        id: 4,
        label: "menuitems.products.text",
        icon: "bx-shopping-bag",
        link: "/product"
    },
    {
        id: 5,
        label: "menuitems.events.text",
        icon: "bx-calendar-event",
        link: "/event"
    },
    {
        id: 6,
        label: "menuitems.banners.text",
        icon: "bx-image-alt",
        link: "/banner"
    }
];
